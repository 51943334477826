import { calendarClient } from './axiosClients'

// Calendar Service - Bookings API
export function getPropertyAvailableDates (propertyId, start_at, end_at) {
  return calendarClient.get(`/api/property/${propertyId}/slots/dates/`, {
    params: { start_at, end_at }
  })
}

export function getPropertyAvailableSlots (propertyId, start_at, end_at) {
  return calendarClient.get(`/api/property/${propertyId}/slots/`, {
    params: { limit: 287, start_at, end_at }
  })
}

export function createPropertyTimeSlot (propertyId, slot) {
  return calendarClient.post(`/api/property/${propertyId}/slots/`, slot)
}

export function deletePropertyTimeSlot (propertyId, slotId) {
  return calendarClient.delete(`/api/property/${propertyId}/slots/${slotId}/`)
}

export function getPropertVisitBooking (propertyId, slotId, bookingId) {
  return calendarClient.get(`/api/property/${propertyId}/slots/${slotId}/bookings/${bookingId}/`)
}

export function updatePropertVisitBooking (propertyId, slotId, bookingId, booking) {
  return calendarClient.put(`/api/property/${propertyId}/slots/${slotId}/bookings/${bookingId}/`, booking)
}

export function deletePropertVisitBooking (propertyId, slotId, bookingId) {
  return calendarClient.delete(`/api/property/${propertyId}/slots/${slotId}/bookings/${bookingId}/`)
}
